<template>
  <v-container fill-height fluid>
    <v-row justify="center">
      <v-col cols="12" sm="6" md="4" lg="2">
        <v-card outlined color="transparent" align="center">
          <div
            class="d-flex justify-center align-center flex-row"
            id="signinHeader"
          >
            <span class="brand-logo">
              <v-icon x-large>mdi-water-circle</v-icon>
            </span>
            <span class="d-flex flex-column brand_main">
              <span>Celestine</span>
              <span>Sign in</span>
            </span>
          </div>

          <v-form class="mt-10">
            <v-text-field label="Username" outlined v-model="username" />
            <v-text-field
              label="Password"
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              outlined
            />
          </v-form>

          <v-btn color="#286aff" block @click="authenticate()"> Sign in </v-btn>

          <v-btn @click="$router.push('/auth/signup')" class="mt-2" text>
            Create an account
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Login",

  methods: {
    async authenticate() {
      const data = {
        username: this.username,
        password: this.password,
        captcha: "",
      };
      const req = await this.$http
        .post("/public/authenticate", data)
        .then((res) => res.data);

      if (req.status === false) {
        if (req.message.includes("email")) {
          var sessionStr = {};
          sessionStr.username = this.username;
          sessionStr.toverify = true;
          localStorage.session = JSON.stringify(sessionStr);

          this.$router.push("/public/auth/verify");
          return;
        }

        this.$dtoast.pop({
          preset: "error",
          heading: `Ooops!`,
          content: req.message,
        });

        return;
      }

      this.$dtoast.pop({
        preset: "success",
        heading: `Success`,
        content: req.message,
      });

      localStorage.token = req.token;

      let session = {};
      session.username = this.username;
      localStorage.session = JSON.stringify(session);

      setTimeout(() => {
        window.location.href = "../dashboard";
      }, 2000);
    },
  },

  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
    };
  },
};
</script>

<style lang="scss">
.v-card {
  min-width: 300px !important;
}
#signinHeader {
  .brand_main {
    text-align: justify;
    span:first-of-type {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 20px;
    }

    span:last-of-type {
      font-family: "Poppins";
      font-size: 13px;
    }
  }
  .brand-logo {
    margin-right: 10px;
    i {
      font-size: 48px !important;
      color: #286aff !important;
    }
  }
}
</style>
